import { devFeatureFlags } from './dev';
import { prodFeatureFlags } from './prod';
import { testFeatureFlags } from './test';
import { stageFeatureFlags } from './stage';
export const featureFlags = (env: string | undefined): any => {
  if (env === 'dev') {
    return devFeatureFlags;
  }
  if (env === 'stage') {
    return stageFeatureFlags;
  }
  if (env === 'test') {
    return testFeatureFlags;
  }

  if (env === 'prod' || env === undefined) {
    return prodFeatureFlags;
  }
};
